<template>
  <div>
    <calendar-monthly-bar v-model="selectedMonths" @input="onMonthSelected" :max-options-size="6" :future-options-size="3" />
    <b-row class="mb-1 align-items-end">
      <!-- Pick Building -->
      <b-col cols="4">
        <b-form-group :label="msg('Pick Building (Not active buildings are not shown)')" label-for="building-select">
          <building-select-box only-active id="building-select" v-model="buildings" multiple clearable @input="refresh"/>
        </b-form-group>
      </b-col>

      <!-- Pick Property -->
      <b-col cols="7">
        <b-form-group :label="msg('Pick Property')" label-for="property-picker">
          <real-estate-picker id="property-picker" multiple v-model="properties" v-bind:buildings="buildings" clearable @input="refresh"/>
        </b-form-group>
      </b-col>

      <!-- Report Button -->
      <b-col cols="1">
        <b-button v-bind:disabled="reportDisabled || loading.requestReport" block variant="primary" @click="requestReport">
          <b-spinner small type="grow" v-if="loading.requestReport"></b-spinner>
          <i v-else class="fa-solid fa-file-arrow-down"></i>
        </b-button>
      </b-col>
    </b-row>




    <!--Income cards-->
    <b-row>
      <b-col cols="12" lg="4">
        <statistic-card-horizontal icon="fa-solid fa-file-contract" v-bind:statistic="stats.totalOfContracts || 0"
                                   v-bind:loading="loading.stats" formatNumber
                                   :statistic-title="$t('contractStats.totalOfContracts')"
                                   icon-type="fontawesome" color="success">
          <template v-slot:footer>
            <div style="min-height: 26px">
              <platform-logo v-for="platform in platforms" :key="platform.name"
                             :platform="platform.name" size="18px" color="primary" class="mr-1">
                <span class="text-secondary" style="font-size: 18px; margin-left: 10px">{{ platform.count }}</span>

              </platform-logo>
            </div>

          </template>
        </statistic-card-horizontal>
      </b-col>
      <b-col cols="12" lg="4">
        <statistic-card-horizontal icon="fas fa-sign-in-alt" v-bind:statistic="stats.totalIncome || 0" post-fix=" €"
                                   formatNumber
                                   v-bind:loading="loading.stats" :statistic-title="$t('contractStats.totalIncome')"
                                   icon-type="fontawesome" color="success">
          <template v-slot:footer>
            <div class="d-none d-lg-block text-danger" style="min-height: 26px"></div>
          </template>
        </statistic-card-horizontal>
      </b-col>
      <b-col cols="12" lg="4">
        <statistic-card-horizontal icon="fa-solid fa-cash-register" v-bind:statistic="(stats.totalNetIncome || 0)" post-fix=" €"
                                   formatNumber
                                   v-bind:loading="loading.stats" :statistic-title="$t('contractStats.totalNetIncome')"
                                   icon-type="fontawesome" color="success">
          <template v-slot:footer>
            <div class="d-none d-lg-block " style="min-height: 26px">
              <i class="fa-solid fa-circle"></i>
              {{ $t('contractStats.withoutRent') }}: {{ (stats.totalNetIncomeWithoutRent || 0).toFixed(2) }} €
            </div>
          </template>
        </statistic-card-horizontal>
      </b-col>
    </b-row>
    <!--Expenses cards-->
    <b-row>
      <b-col cols="12" lg="4">
        <statistic-card-horizontal icon="fa-solid fa-broom"
                                   v-bind:statistic="-(stats.totalExpensesWithoutRent || 0)" post-fix=" €"
                                   formatNumber
                                   v-bind:loading="loading.stats" :statistic-title="$t('contractStats.totalExpenses')"
                                   icon-type="fontawesome" color="warning">
          <template v-slot:footer>
            <div class="d-none d-lg-block text-danger" style="min-height: 26px"></div>
          </template>
        </statistic-card-horizontal>
      </b-col>
      <b-col cols="12" lg="4">
        <statistic-card-horizontal icon="fa-solid fa-chart-pie"
                                   v-bind:statistic="-(stats.totalExternalPlatformCommission + stats.totalExternalPlatformTransactionFee)" post-fix=" €"
                                   formatNumber
                                   v-bind:loading="loading.stats" :statistic-title="$t('contractStats.commissions')"
                                   icon-type="fontawesome" color="warning">
          <template v-slot:footer>
            <div class="d-none d-lg-block text-danger" style="min-height: 26px">
              <i class="fa-solid fa-circle text-danger"></i>
              {{ $t('contractStats.transactionFees') }}:
              {{ ((stats.totalExternalPlatformTransactionFee) * -1).toFixed(2) }} €
            </div>
          </template>
        </statistic-card-horizontal>
      </b-col>
      <b-col cols="12" lg="4">
        <statistic-card-horizontal icon="fa-solid fa-people-roof"
                                   v-bind:statistic="-(stats.totalManagementFee || 0)" post-fix=" €"
                                   formatNumber
                                   v-bind:loading="loading.stats" :statistic-title="$t('contractStats.managementFee')"
                                   icon-type="fontawesome" color="warning">
          <template v-slot:footer>
            <div class="d-none d-lg-block text-danger" style="min-height: 26px"></div>
          </template>
        </statistic-card-horizontal>
      </b-col>
    </b-row>
    <b-card>
      <h4>{{ $t('contractStats.topPropertiesByIncomes') }}</h4>
      <properties-stacked-char :loading="loading.stats" v-bind:incomesByProperties="propertiesIncomes"/>
    </b-card>
    <contract-monthly-expenses-table :loading="loading.stats" v-bind:stats="stats"/>
    <b-row style="min-height: 300px">
      <b-col cols="12" md="6">
        <b-card style="height: 100%">
          <b-row>
            <b-col cols="9"><h4>{{ $t('contractStats.platforms') }}</h4></b-col>
            <b-col cols="3">
              <div>
                <b-form-checkbox inline switch size="sm" v-model="showPlatformsIncomes">
                  {{ $t('contractStats.netIncome') }}
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
          <platform-doughnut-chart v-bind:platforms-data="platforms" v-bind:loading="loading.stats" v-bind:show-incomes="showPlatformsIncomes"/>
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <b-card style="height: 100%">
          <h4>{{ $t('contractStats.platforms') }}</h4>
          <capacity-radial-chart v-bind:capacity-data="capacityData" v-bind:loading="loading.stats"/>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import CalendarMonthlyBar from "@/components/widgets/calendar/CalendarMonthlyBar.vue";
import RealEstatePicker from "@/components/components/picker/realEstatesPicker.vue";
import PlatformLogo from "@/components/widgets/PlatformLogo.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import ContractMonthlyExpensesTable from "@/components/panels/dashboard/contractMonthlyExpensesTable.vue";
import PropertiesStackedChar from "@/components/chart/realEstates/contract/PropertiesStackedChar.vue";
import PlatformDoughnutChart from "@/components/chart/realEstates/contract/PlatformDoughnutChart.vue";
import CapacityRadialChart from "@/components/chart/realEstates/contract/CapacityRadialChart.vue";
import BuildingSelectBox from "@/components/components/picker/buildingSelectBox.vue";

const stats = () => ({
  totalOfContracts: 0,
  totalIncome: 0,
  totalNetIncome: 0,
  totalNetIncomeWithoutRent: 0,
  totalExternalPlatformCommission: 0,
  totalExternalPlatformTransactionFee: 0,
  realEstatesCalculations: {},
  contracts: []
})

export default {
  name: "contractRentMonthlyDashboard",
  components: {
    BuildingSelectBox,
    CapacityRadialChart,
    PlatformDoughnutChart,
    PropertiesStackedChar,
    ContractMonthlyExpensesTable, StatisticCardHorizontal, PlatformLogo, RealEstatePicker, CalendarMonthlyBar
  },
  data: () => ({
    selectedMonths: {start: null, end: null},
    buildings: [],
    properties: [],
    showPlatformsIncomes: false,
    stats: stats(),
    loading: {
      requestReport: false,
      stats: false,
    },
  }),
  created() {
    this.$root.$on('crm::branch-updated', this.refresh);
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.refresh);
  },
  computed: {
    platforms() {
      let result = {};
      if (this.stats == null || this.stats.contracts == null) {
        return [];
      }
      for (let contract of this.stats.contracts) {
        let platformName = contract?.bnbStyleRent?.platform;
        if (platformName == null) {
          // console.warn('Platform name is null', contract)
          continue;
        }
        let platform = result[platformName];
        if (platform == null) {
          platform = {name: platformName, platform: platformName, paymentFee: 0, netPayment: 0, totalPayment: 0, count: 0};
          result[platformName] = platform;
        }
        platform.paymentFee += contract.bnbStyleRent.totalPaymentFee;
        platform.netPayment = parseFloat((platform.netPayment + contract.bnbStyleRent.netPaymentIncome).toFixed(2));
        platform.totalPayment += contract.bnbStyleRent.totalPayment;
        platform.count++;
      }

      return Object.values(result);
    },
    propertiesIncomes() {
      if (this.stats == null)
        return [];
      return Object.values(this.stats.realEstatesCalculations || {})
          .map(item => ({
            fullAddress: item.fullAddress,
            totalIncome: item.totalIncome,
            totalOfContracts: item.totalOfContracts,
            totalNetIncome: (item.totalNetIncome - item.managementFee),
            totalNetIncomeWithoutRent: item.totalNetIncomeWithoutRent,
            totalCommission: item.totalCommission,
            managementFee: item.managementFee,
            rent: item.expenses.rent,
            totalExternalPlatformCommission: (item.externalPlatformCommission + item.externalPlatformTransactionFee),
            totalExpenses: item.expenses.totalExpenses,
            totalExpensesWithoutRent: item.expenses.totalExpensesWithoutRent
          }))
          .filter(item => item.managementFee > 0)
          .sort((a, b) => b.totalNetIncome - a.totalNetIncome).slice(0, 12);
    },
    capacityData() {
      if(this.stats == null || this.stats.months == null || this.stats.months.length === 0)
        return {totalDays: 0, totalOfProperties: 0, totalOfContracts: 0, totalOfNights: 0};
      let $this = this;
      let totalDays = this.stats.months.reduce((acc, month) => acc + $this.$moment(month, "MM-YYYY").daysInMonth() , 0);
      let totalOfProperties = Object.keys(this.stats.realEstatesCalculations || {}).length;
      let totalOfContracts = this.stats.totalOfContracts;
      let totalOfNights = Object.values(this.stats.realEstatesCalculations || {}).reduce((acc, calculation) => acc + calculation.totalOfNights, 0);
      return {totalDays, totalOfProperties, totalOfContracts, totalOfNights};
    },
    reportDisabled(){
      return !Array.isArray(this.buildings) || this.buildings.length !== 1;
    }
  },
  methods: {
    ...mapActions('realEstateContractsStats', ['getExpensesStats', 'getExpensesStatsReport']),
    refresh() {
      this.loading.stats = true;
      this.getExpensesStats({...this.selectedMonths,  properties: this.properties, buildings: this.buildings}).then(data => {
        this.loading.stats = false;
        this.stats = {...stats(), ...data.stats}
      });
    },
    requestReport() {
      let $this = this;
      this.loading.requestReport = true;
      this.getExpensesStatsReport({...this.selectedMonths,  properties: this.properties, buildings: this.buildings}).then(response => {
        console.log("getExpensesStatsReport blob", response)
        const link=document.createElement('a');
        link.href=window.URL.createObjectURL(new Blob([response.data]));
        link.download=response.headers['content-disposition'].split('filename=')[1];
        link.click();
      }, error => {
        console.error("getExpensesStatsReport error", error)
      }).finally(() => {
        $this.loading.requestReport = false;
      });
    },
    onMonthSelected(month) {
      this.selectedMonths = month;
      this.refresh();
      //this.$emit('monthSelected', month)
    },
  }
}
</script>


<style scoped>

</style>
